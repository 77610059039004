import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../pages/game/Game";
import { diarects } from "../../helpers/Words";
import { positiongridAction, update } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import "./alphabet.css";

function Alphabet({ alphabetPos, attemptVal, gridPos }) {
  const {userId, currAttempt, keyState } = useContext(AppContext);
  const wordgrid = useSelector((state) => state.isWordGrid);
  const [start, setStart] = useState(false);
  const alphabet = wordgrid[attemptVal][alphabetPos];
  var shobdle = useSelector((state) => state.isShobdle);
  var positiongrid = useSelector((state) => state.isPositiongrid);
  const alphabetElement = document.getElementsByClassName("alphabet");

  const dispatch = useDispatch();
  const historyObject = useSelector((state) => state.isHistoryData);

  const rprl = shobdle["shobdle"][alphabetPos] === alphabet;
  const wprl =
    !rprl && alphabet !== "" && shobdle["shobdle"].includes(alphabet);
  const rpwl =
    !rprl &&
    !wprl &&
    alphabet !== "" &&
    shobdle["shobdle"][alphabetPos]
      .split("")
      .filter((x) => !new Set(diarects).has(x))
      .some((value) =>
        alphabet
          .split("")
          .filter((x) => x !== "্")
          .includes(value)
      );
  const wpwl =
    !rprl &&
    !wprl &&
    !rpwl &&
    alphabet !== "" &&
    shobdle["shobdle"]
      .join("")
      .toString()
      .split("")
      .filter((x) => !new Set(diarects).has(x))
      .some((value) =>
        alphabet
          .split("")
          .filter((x) => x !== "্")
          .includes(value)
      );

  const absent = !rprl && !wprl && !rpwl && !wpwl;

  const alphabetState =
    currAttempt.attemptVal - 1 > attemptVal
      ? positiongrid[attemptVal][alphabetPos]
      : currAttempt.attemptVal - 1 === attemptVal && !start
      ? alphabetAnimation()
      : "";

  useEffect(() => {
    if (currAttempt.attemptVal > attemptVal) {
      if (absent) {
        for (
          let i = 0;
          i <
          alphabet
            .split("")
            .filter((x) => x !== "্")
            .filter((x) => !new Set(diarects).has(x)).length;
          i++
        ) {
          if (
            !["rprl", "wprl", "rpwl","wpwl"]
            .includes(
              keyState[
                alphabet
                  .split("")
                  .filter((x) => x !== "্")
                  .filter((x) => !new Set(diarects).has(x))[i]
              ]
            )
          ) {
            keyState[
              alphabet
                .split("")
                .filter((x) => x !== "্")
                .filter((x) => !new Set(diarects).has(x))[i]
            ] = "absent";
          }
        }
        positiongrid[attemptVal][alphabetPos] = "absent";
      } else if (wpwl) {
        for (
          let i = 0;
          i <
          alphabet
            .split("")
            .filter((x) => x !== "্")
            .filter((x) => !new Set(diarects).has(x)).length;
          i++
        ) {
          if (
            !["rprl", "wprl", "rpwl"]
            .includes(
              keyState[
                alphabet
                  .split("")
                  .filter((x) => x !== "্")
                  .filter((x) => !new Set(diarects).has(x))[i]
              ]
            )
          ) {
            keyState[
              alphabet
                .split("")
                .filter((x) => x !== "্")
                .filter((x) => !new Set(diarects).has(x))[i]
            ] = "wpwl";
          }
        }
        positiongrid[attemptVal][alphabetPos] = "wpwl";
      } else if (rpwl) {
        for (
          let i = 0;
          i <
          alphabet
            .split("")
            .filter((x) => x !== "্")
            .filter((x) => !new Set(diarects).has(x)).length;
          i++
        ) {
          if (
            !["rprl", "wprl"]
            .includes(
              keyState[
                alphabet
                  .split("")
                  .filter((x) => x !== "্")
                  .filter((x) => !new Set(diarects).has(x))[i]
              ]
            )
          ) {
            keyState[
              alphabet
                .split("")
                .filter((x) => x !== "্")
                .filter((x) => !new Set(diarects).has(x))[i]
            ] = "rpwl";
          }
        }
        positiongrid[attemptVal][alphabetPos] = "rpwl";
      } else if (wprl) {
        for (
          let i = 0;
          i <
          alphabet
            .split("")
            .filter((x) => x !== "্")
            .filter((x) => !new Set(diarects).has(x)).length;
          i++
        ) {
          if (
            !["rprl"]
            .includes(
              keyState[
                alphabet
                  .split("")
                  .filter((x) => x !== "্")
                  .filter((x) => !new Set(diarects).has(x))[i]
              ]
            )
          ) {
            keyState[
              alphabet
                .split("")
                .filter((x) => x !== "্")
                .filter((x) => !new Set(diarects).has(x))[i]
            ] = "wprl";
          }
        }
        positiongrid[attemptVal][alphabetPos] = "wprl";
      } else if (rprl) {
        for (
          let i = 0;
          i <
          alphabet
            .split("")
            .filter((x) => x !== "্")
            .filter((x) => !new Set(diarects).has(x)).length;
          i++
        ) {
          keyState[
            alphabet
              .split("")
              .filter((x) => x !== "্")
              .filter((x) => !new Set(diarects).has(x))[i]
          ] = "rprl";
        }
        positiongrid[attemptVal][alphabetPos] = "rprl";
      }

      dispatch(positiongridAction(positiongrid));
      localStorage.setItem(userId +"-positiongrid", JSON.stringify(positiongrid));
      keyAnimation();
    }
  }, [currAttempt.attemptVal]);

  function waitforme(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  async function alphabetAnimation() {
    var count = 0;
    setStart(true);

    for (
      var i = (historyObject.currAttempt.attemptVal - 1) * 4;
      i < historyObject.currAttempt.attemptVal * 4;
      i++
    ) {
      await waitforme(400);

      alphabetElement[i].classList.remove("clicked");
      void alphabetElement[i].offsetWidth;
      alphabetElement[i].classList.add("clicked");

      await waitforme(100);

      alphabetElement[i].id =
        positiongrid[historyObject.currAttempt.attemptVal - 1][count];
      count += 1;
    }
  }

  async function keyAnimation() {
    await waitforme(2200);
    dispatch(update(keyState));
  }

  return (
    <div
      className="alphabet"
      id={alphabetState}
      data-contrast={historyObject.contrast ? "contrast" : "normal"}
    >
      {alphabet}
    </div>
  );
}

export default Alphabet;
