import React, { useContext } from "react";
import { AppContext } from "../../pages/game/Game";
import { useSelector, useDispatch } from 'react-redux';
import "./key.css";
import { shiftAction } from "../../actions";


function Key({keyVal, bigKey, actionKey, keyColor}) {
  const {onSelectKey, onEnter, onBackspace} = useContext(AppContext);
  const gameoverState = useSelector(state => state.isGameover);
  const toggleKeyboard = useSelector(state => state.isShiftClicked);
  const dispatch = useDispatch();
  
  
  const selectKey = () => {
    if(gameoverState) return;

    if (actionKey == null){
      onSelectKey(keyVal);
      
    } else if (actionKey === "enter"){
      onEnter();
      
    } else if (actionKey === "backspace"){
      onBackspace();
    } else if (actionKey === "shift"){
      dispatch(shiftAction(!toggleKeyboard));
    }
  };

  



  return (
    <div className="key" id={bigKey ? "big" : keyColor  } onClick={selectKey}>
      {keyVal}
    </div>
  );
}

export default Key;



