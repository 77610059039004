import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dcLogo from '../../assets/DC Cover Logo.png';
import {generateWordSet} from '../../helpers/Words';
import { useDispatch, useSelector } from 'react-redux';
import { signInAction, uploadShobdleAction } from '../../actions';
import { SignIn } from '../../containers';
import { isSignedIn, auth, uploadShobdle, getShobdle } from '../../helpers/firebase';
import './home.css';



function Home() {
  const [inputValues, setInputValues] = useState(['', '', '', '']);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const inputRefs = useRef(inputValues.map(() => React.createRef()));
  const [wordSet, setWordSet] = useState(new Set());
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showSignIn = useSelector(state => state.isSignIn);
  var snackbar = document.getElementById("snackbar");
  const shobdle = useSelector((state) => state.isShobdle);
  const today = new Date();
  const formattedDate = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;


  const isValidBengaliLetter = (value) => {
    // Regular expression to match valid Bengali Unicode code points
    const bengaliCodePointsRegex = /^[\u0980-\u09FF]+$/;
  
    return bengaliCodePointsRegex.test(value);
  };

  const handleInputChange = (index, value) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);
  };
  

  const handleCreateShobdle = async () => {
    // Check if all fields contain valid Bengali single letters or combined letters
    const isValidInput = inputValues.every((value) => {
      const isValid = value.trim() !== '' && isValidBengaliLetter(value);
      return isValid;
    });
  
    if (isValidInput) {
      setError('');
  
      const combinedText = inputValues.join('');
  
      if (wordSet.has(combinedText)) {
        setLoading(true);
  
        const uploadNewShobdle = {};
        inputValues.forEach((value, index) => {
          uploadNewShobdle[index] = value;
        });
  
        dispatch(uploadShobdleAction(uploadNewShobdle));
  
        const user = isSignedIn();
  
        if (user) {
          try {
            // Check if the user already has shobdle
            const shobdleSuccess = await getShobdle(user.uid, formattedDate, shobdle, dispatch);

            if (shobdleSuccess){
              setError('আজকের শব্দল আগেই আপলোড করা হয়েছে');
            } else {
              // Upload the shobdle to the database
              const uploadSuccess = await uploadShobdle(user.uid, uploadNewShobdle);
    
              if (uploadSuccess) {
                setLoading(false);
                setMessage("আপলোড সম্পন্ন হয়েছে!");
                snackbar.className = "show";
                setTimeout(function () {
                  snackbar.className = snackbar.className.replace("show", "");
                }, 3000);
                navigate(`/profile/${user.uid}`);
              } else {
                console.log("Upload failed.");
              }
            }
          } catch (error) {
            setLoading(false);
            console.error("Error during upload:", error);
          }
        } else {
          dispatch(signInAction(true));
        }
      } else {
        setError('শব্দটি পাওয়া যায়নি');
        return;
      }
    } else {
      setError('চারটি অক্ষর দিতে হবে এবং শুদ্ধ বাংলা অক্ষর হতে হবে!');
    }
  };

  const handleMyAccountClick = () => {
    const user = isSignedIn();
    if (user) {
        navigate(`/profile/${user.uid}`);
    } else {
        dispatch(signInAction(true));
    }
  };

  useEffect(() => {
    // Get the valid wordlist from our library
    setWordSet(generateWordSet().wordSet);
  }, []);

  useEffect(() => {
    // Add an event listener for authentication state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // If the user is signed in, close the sign-in modal and navigate to the profile page
        dispatch(signInAction(false));
      }
    });

    // Cleanup the event listener when the component is unmounted
    return () => unsubscribe();
  }, [dispatch]);

    // useEffect to focus on the first input field if it's empty
useEffect(() => {
  if (inputValues[0].trim() === '') {
    // Use a timeout to ensure the focus occurs after rendering
    const timeoutId = setTimeout(() => {
      // Focus on the first input field
      inputRefs.current[0].current.focus();
    }, 0);

    // Cleanup the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }
}, [inputValues]);
  
  return (
    <div className="home">
      {showSignIn ? <SignIn/> : null}
      <div className="home-body">
        <p className='shobdle_logo'>শব্দল</p>
        <h1 className="title">চার বর্ণে গল্প</h1>
        <div className="input-row">
          {inputValues.map((value, index) => (
            <input
              key={index}
              ref={inputRefs.current[index]}
              type="text"
              value={value}
              placeholder="অ" // Placeholder for Bengali alphabet
              className="input-field"
              onChange={(e) => handleInputChange(index, e.target.value)}
              onInput={(e) => handleInputChange(index, e.target.value)}
            />
          ))}
        </div>
        <button className="create-button" onClick={handleCreateShobdle} disabled={loading}>
            {loading ? 'আপলোড হচ্ছে...' : 'শব্দল সুনিশ্চিত 😎'}
        </button>
        {error && <p className="error-message">{error}</p>}
        <p className="my-account-link" onClick={handleMyAccountClick}>
            আমার প্রোফাইল
            </p>
        <div className='disclaimer'>
          <p>চার বর্ণে লিখে ফেলো তোমার দিনলিপি</p>
          <p>বন্ধুরা আবিষ্কার করবে তোমার আজকের শব্দল!!!</p>
        </div>
      </div>
      <div id="snackbar">{message}</div>
      <div className="footer">
        <a href="https://devs-core.com/" target="_blank" rel="noopener noreferrer">
          <img src={dcLogo} alt="Dev's Core Logo" className="footer-image" />
          <p className="footer-text">© A Product of Devs Core</p>
        </a>
      </div>
    </div>
  );
}

export default Home;
