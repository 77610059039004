import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import ReactDOM  from 'react-dom';
import { useNavigate } from 'react-router-dom';
import './shobdleChecker.css';
import { shobdleCheckerAction } from '../../actions';


function ShobdleChecker({username}) {
    const dispatch = useDispatch();
    const historyObject = useSelector(state => state.isHistoryData);
    const navigate = useNavigate();
     
    const handleClick = () => {
        dispatch(shobdleCheckerAction(false));
        navigate('/');
      };

    return ReactDOM.createPortal(
        <div className="shobdleChecker_bg" data-theme={historyObject.theme}>
            <div className="shobdleChecker">
                <div className="shobdleChecker-body">
                    <h1 className="shobdleChecker-icon">🤷🏻</h1>
                    <div className="shobdleChecker-title">{username} তার আজকের শব্দল এখনো প্রকাশ করেনি</div>
                    <button className="shobdleChecker-button" onClick={handleClick}>
                    আমার শব্দল দেই 😎
                    </button>
                </div>
                <div className='shobdleChecker-disclaimer'>
                    <p>আপনার শব্দল প্রকাশ করুন এবং দেখুন কতজন বন্ধু তা বের করতে পারে</p>
                </div>
            </div>

        </div>,
        document.getElementById('portal')
      );
}

export default ShobdleChecker;