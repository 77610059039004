const shobdleChekerReducer = (state = false, action) => {
    switch (action.type) {

        case "SHOBDLECHECKER":
            return action.shobdleCheckerState;

        default: return state;
    }
};


export default shobdleChekerReducer;