import React, { useRef, useEffect, useMemo, useState } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { historyAction } from '../../actions';
import { useSelector } from 'react-redux';
import ReactDOM  from 'react-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { StatusIcon } from '../../components';
import { getPlayHistory } from '../../helpers/firebase';
import DataTable from 'react-data-table-component';
import * as htmlToImage from 'html-to-image';
import { toBlob } from 'html-to-image';
import './history.css';



function History() {
    const { userId } = useParams();
    const dispatch = useDispatch();
    let today = new Date();
    var date = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;
    var snackbar = document.getElementById("history-snackbar");
    const [message, setMessage] = useState("");

    const ref = useRef(null);
    const isHistory = useSelector(state => state.isHistory);
    const historyObject = useSelector(state => state.isHistoryData);
    const positiongrid = useSelector(state => state.isPositiongrid);
    const userInfo = JSON.parse(localStorage.getItem(userId + '-creatorInfo'));
    const [playHistory, setPlayHistory] = useState([]);
    const navigate = useNavigate();
    const [result, setResult] = useState("");
    const isWebShareSupported = (navigator.canShare && navigator.share);
    const [generating, setGenerating] = useState(false);

    //Style of the Latest Jobs Table
  const tableStyle = {
    headCells: {
      style: {
        fontSize: "16px",
        paddingTop: "12px",
        paddingBottom: "12px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        fontSize: "18px",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "#F4F7FF",
        borderBottomColor: "#CEDDFF",
        outline: "1px solid #CEDDFF",
      },
    },
  };

  //Columns of the Latest Jobs Table
  const columns = useMemo(
    () => [
      {
        id: "status",
        cell: (row) => (
          <StatusIcon
            status={row.status}
          />
        ),
        width: "20px",
        ignoreRowClick: true,
      },
      {
        id: "name",
        name: "খেলোয়াড়",
        selector: (row) => row.name,
        sortable: true,
        wrap: true,
      },
      {
        id: "attempts",
        name: "চেষ্টার সংখ্যা",
        selector: (row) => row.attempts,
        sortable: true,
        center: true,
      },
    ],
    []
  );

    useEffect(() => {
        let newResult = "";
        for (var i = 0; i < historyObject.currAttempt.attemptVal; i++) {
            for (var j = 0; j < 4; j++) {
                if (positiongrid[i][j] === "absent") newResult += "⬛ ";
                if (positiongrid[i][j] === "wpwl") newResult += "🟪 ";
                if (positiongrid[i][j] === "rpwl") newResult += "🟦 ";
                if (positiongrid[i][j] === "wprl") newResult += "🟨 ";
                if (positiongrid[i][j] === "rprl") newResult += "🟩 ";
            }
            newResult += "\n";
        }
        setResult(newResult);

        const fetchPlayHistory = async () => {
            const historyData = await getPlayHistory(userId, date);
            setPlayHistory(Object.values(historyData));
          };

        const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            dispatch(historyAction(false));
        }
        };
        // Fetch play history
        fetchPlayHistory();

        document.addEventListener('click', handleClickOutside, true);
        return () => {
        document.removeEventListener('click', handleClickOutside, true);
        };
    }, [ isHistory ]);

    const share = () => {
        var shareResult = "";

        for (var i = 0; i < historyObject.currAttempt.attemptVal; i++){
            for (var j = 0; j < 4; j++){
                if (positiongrid[i][j] === "absent") shareResult += "⬛";
                if (positiongrid[i][j] === "wpwl") shareResult += "🟪";
                if (positiongrid[i][j] === "rpwl") shareResult += "🟦";
                if (positiongrid[i][j] === "wprl") shareResult += "🟨";
                if (positiongrid[i][j] === "rprl") shareResult += "🟩";
            }
            shareResult += "\n";
        }
        shareResult = userInfo.displayName + " এর শব্দল " + historyObject.date + "\n\n" + shareResult;

        copyToClipboard(shareResult);

        setMessage("কপি করা হয়েছে");
        snackbar.className = "show";
        setTimeout(function(){ snackbar.className = snackbar.className.replace("show", ""); }, 3000);
    };

    const shareWithWebShare = async () => {
      setGenerating(true);
      // Get the node to share
      const node = document.getElementById('share-node');

      var shareResult = "";

        for (var i = 0; i < historyObject.currAttempt.attemptVal; i++){
            for (var j = 0; j < 4; j++){
                if (positiongrid[i][j] === "absent") shareResult += "⬛";
                if (positiongrid[i][j] === "wpwl") shareResult += "🟪";
                if (positiongrid[i][j] === "rpwl") shareResult += "🟦";
                if (positiongrid[i][j] === "wprl") shareResult += "🟨";
                if (positiongrid[i][j] === "rprl") shareResult += "🟩";
            }
            shareResult += "\n";
        }
        shareResult = userInfo.displayName + " এর শব্দল " + historyObject.date + "\n\n" + shareResult;


      // Generate the image
      try {
        const blob = await htmlToImage.toBlob(node);
      
        // Create a promise that resolves when the image is shared or the fallback is used
        const sharePromise = new Promise(async (resolve, reject) => {
          const toShare = {
            title: shareResult,
            files: [
              new File([blob], 'shobdle.png', { type: blob.type, lastModified: Date.now()})
            ]
          };
      
          // Share the image
          if (navigator.canShare(toShare)) {
            try {
              await navigator.share(toShare);
              console.log('Shared Shobdle result');
              resolve();
            } catch (err) {
              console.error('Error sharing Shobdle result:', err);
              share();
              reject(err);
            }
          } else {
            // Fallback to sharing text
            delete toShare.files;
            share();
            resolve();
          }
        });
      
        // Wait for the sharePromise to resolve before setting generating state to false
        await sharePromise;
      } catch (error) {
        console.error('Error generating image:', error);
        share();
      } finally {
        setGenerating(false); // Set generating state to false when image generation is complete
      }
    };


    function copyToClipboard(text) {
        if (window.clipboardData && window.clipboardData.setData) {
            // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
            return window.clipboardData.setData("Text", text);
    
        }
        else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {

            var textarea = document.createElement("textarea");
           // textarea.textContent = text;
            textarea.value = text;
            textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
            document.body.appendChild(textarea);
            textarea.select();
            try {
                return document.execCommand("copy");  // Security exception may be thrown by some browsers.
            }
            catch (ex) {
                console.warn("Copy to clipboard failed.", ex);
                return prompt("Copy to clipboard: Ctrl+C, Enter", text);
            }
            finally {
                document.body.removeChild(textarea);
            }
        }
    }
     

    return ReactDOM.createPortal(
        <div className="history_bg" data-theme={historyObject.theme}>
            <div ref={ref} className="history">
                <div className= "history-close-icon">
                    <AiOutlineCloseCircle size={27} onClick={() => dispatch(historyAction(false))}/>
                </div>
                <div className='share-node' id='share-node'>
                  <div className="history-body">
                      <h1 className='name'>{userInfo.displayName}</h1>
                      <h1 className='sub-text'>{"এর শব্দলে আমার ফলাফল"}</h1>
                      <div className='bar'></div>
                      <div className="history-win">
                                <p>জিতার %</p>
                                <div className="container">
                                  <div className="text-overlay">
                                    {historyObject.winPer}
                                    <p>%</p>
                                  </div>
                                </div>
                            </div>
                      <div className="stats">
                          <div className="played">
                              <h2>{historyObject.played}</h2>
                              <p>খেলা হয়েছে</p>
                          </div>
                          <div className="currStreak">
                                <h2>{historyObject.currStreak}</h2>
                                <p>বর্তমান স্ট্রীক</p>
                            </div>
                            <div className="maxStreak">
                                <h2>{historyObject.maxStreak}</h2>
                                <p>সর্বোচ্চ স্ট্রীক</p>
                            </div>
                      </div>
                      <div className="history-result">
                          {result.split('\n').map((line, index) => (
                              <React.Fragment key={index}>
                                  {line}
                                  <br />
                              </React.Fragment>
                          ))}
                      </div>
                  </div>
                </div>
                <div className='history-body'>
                  <div className="history-disclaimer">
                      <p>{userInfo.displayName + " কে মেনশন দিয়ে শেয়ার কর তোমার ফলাফল"}</p>
                  </div>
                  <button className='share-button' onClick={isWebShareSupported ? shareWithWebShare : share}>
                    {generating ? 'Generating...' : 'শেয়ার'} 
                  </button>
                  <p className="history-table-title">
                  {userInfo.displayName + " এর শব্দল আজকে খেলেছে " + playHistory.length + " জন"}
                  </p>
                  <div className='bar'></div>
                  {playHistory.length > 0 ? (
                  <div className="history-table">
                      <DataTable
                      data={playHistory}
                      columns={columns}
                      customStyles={tableStyle}
                      persistTableHead
                      highlightOnHover
                      defaultSortFieldId="attempts"
                      defaultSortAsc={true}
                      />
                  </div>
                  ) : null}
                  <button className="history-home-button" onClick={() => navigate('/')}>
                  আমার শব্দল 😎
                  </button>
                  <div id="history-snackbar">{message}</div>
                </div>
            </div>

        </div>,
        document.getElementById('portal')
      );
}

export default History;