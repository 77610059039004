const gameoverReducer = (state = false, action) => {
    switch (action.type) {

        case "GAMEOVER":
            return action.gameoverState;

        default: return state;
    }
};


export default gameoverReducer;