import { positionGridDefault } from '../helpers/Words';

const positiongridReducer = (state = positionGridDefault, action) => {
    switch (action.type) {

        case "POSITIONGRID":
            return {
                ...state,
                ...action.positiongridState

            };

        default: return state;
    }
};


export default positiongridReducer;