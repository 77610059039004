import React, { useContext, useEffect } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import {historyDataAction, settingsAction, switchAction} from '../../actions';
import ReactDOM  from 'react-dom';
import { ToggleSwitch } from '../../components';
import { AppContext } from '../../pages/game/Game';
import './settings.css';


function Settings() {
    const {userId} = useContext(AppContext);
    const dispatch = useDispatch();
    const isSwitch = useSelector(state => state.isSwitch);
    const historyObject = useSelector(state => state.isHistoryData);

   const handleChange = event => {
       isSwitch[event.target.id] = event.target.checked;
       dispatch(switchAction(isSwitch));
   };

   useEffect(() => {
        if (isSwitch.darkTheme == null && isSwitch.contrastMode == null && historyObject.theme == null ){
            dispatch(switchAction({darkTheme: false, contrastMode: false}));
        } else if ((isSwitch.darkTheme == null || isSwitch.darkTheme == null) && (historyObject.theme != null || historyObject.contrast != null)){
            historyObject.theme === "dark" ? 
            dispatch(switchAction({darkTheme: true, contrastMode: historyObject.contrast})) : 
            dispatch(switchAction({darkTheme: false, contrastMode: historyObject.contrast}));
        }
   }, []);

   useEffect(() => {
        if (historyObject.theme != null && historyObject.contrast != null){
            historyObject.theme = isSwitch.darkTheme ? "dark" : "light";
            historyObject.contrast = isSwitch.contrastMode;
        } else {
            historyObject["theme"] = isSwitch.darkTheme ? "dark" : "light";
            historyObject["contrast"] = isSwitch.contrastMode;
        }

        dispatch(historyDataAction(historyObject));
        localStorage.setItem(userId +'-history', JSON.stringify(historyObject));

   },[isSwitch]);

    return ReactDOM.createPortal(
        <div className="settings_bg" data-theme={historyObject.theme}>
            <div className="settings">
                <div className= "settings-close-icon">
                    <AiOutlineCloseCircle size={22} onClick={() => dispatch(settingsAction(false))}/>
                </div>
                <div className="settings-body">
                    <h1>সেটিংস</h1>
                    <div className="dark-theme">
                        <p>ডার্ক থিম</p>
                        <ToggleSwitch checked={isSwitch.darkTheme} onChange={handleChange} id="darkTheme"/>
                        
                    </div>
                    <div className="contrast-mode">
                        <p>কনট্রাস্ট মোড </p>
                        <ToggleSwitch checked={isSwitch.contrastMode} onChange={handleChange} id="contrastMode"/>     
                    </div>
                    <div className="contact">
                        <p>যোগাযোগ</p>
                        <a href="https://devs-core.com/contact-devs-core/">ওয়েবসাইট</a>
                    </div>
                    <div className="add-word">
                        <p>নতুন শব্দ যুক্ত করুন</p>
                        <a href="https://devs-core.com/contact-devs-core/">যুক্ত করুন</a>
                    </div>
                </div>
                <div className="settings-footer">
                        <h4>© ২০২৪ ডেভস কোর</h4>
                </div>
            </div>
        </div>,
        document.getElementById('portal')
      );
}

export default Settings;