const shobdleReducer = (state = {"shobdle" : ["স্বা", "গ", "ত", "ম"]}, action) => {
    switch (action.type) {

        case "SHOBDLE":
            return {
                ...state,
                ...action.shobdleState
            };

        default: return state;
    }
};


export default shobdleReducer;
