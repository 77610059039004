const historyReducer = (state = false, action) => {
    switch (action.type) {

        case "HISTORY":
            return action.historyState;

        default: return state;
    }
};


export default historyReducer;