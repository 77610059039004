const shiftReducer = (state = false, action) => {
    switch (action.type) {

        case "SHIFT":
            return action.shiftState;

        default: return state;
    }
};


export default shiftReducer;