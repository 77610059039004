
const keyStateReducer = (state = {}, action) => {
    switch (action.type) {

        case "UPDATE":
            return {
                ...state,
                ...action.keyState
            };

        default: return state;
    }
};


export default keyStateReducer;

