import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  Home,
  Game,
  Profile,
} from "../pages";

export const RouterConfig = () => {

  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/profile/:userId" element={<Profile />} />
      <Route path="/shobdle/:userId" element={<Game />} />
      <Route path="/profile" element={<Navigate to="/" />} />
      <Route path="/shobdle" element={<Navigate to="/" />} />
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default RouterConfig;
