import React from 'react';
import './toggleSwitch.css';


const ToggleSwitch = ({checked, onChange, id}) => {
  
    return (
      <label className="toggle-switch">
        <input type="checkbox" checked={checked == null ? false : checked} onChange={onChange} id={id}/>
        <span className="switch" />
      </label>
    );
  }

export default ToggleSwitch;