const triedWordsReducer = (state = {}, action) => {
    switch (action.type) {

        case "TRIEDWORDS":
            return {
                ...state,
                ...action.triedWordState

            };

        default: return state;
    }
};


export default triedWordsReducer;