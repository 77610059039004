import { wordgridDefault } from '../helpers/Words';

const wordgridReducer = (state = wordgridDefault, action) => {
    switch (action.type) {

        case "WORDGRID":
            return {
                ...state,
                ...action.wordgridState

            };

        default: return state;
    }
};


export default wordgridReducer;