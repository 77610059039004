export const signInAction = (signInState) => {
    return {
        type: 'SIGNIN',
        signInState
    }
}

export const update = (keyState) => {
    return {
        type: 'UPDATE',
        keyState
    }
}

export const gameoverAction = (gameoverState) => {
    return {
        type: 'GAMEOVER',
        gameoverState
    }
}

export const historyAction = (historyState) => {
    return {
        type: 'HISTORY',
        historyState
    }
}

export const historyDataAction = (historyDataState) => {
    return {
        type: 'HISTORYDATA',
        historyDataState
    }
}

export const settingsAction = (settingsState) => {
    return {
        type: 'SETTINGS',
        settingsState
    }
}

export const helpAction = (helpState) => {
    return {
        type: 'HELP',
        helpState
    }
}

export const switchAction = (switchState) => {
    return {
        type: 'SWITCH',
        switchState
    }
}

export const shiftAction = (shiftState) => {
    return {
        type: 'SHIFT',
        shiftState
    }
}

export const triedWordsAction = (triedWordState) => {
    return {
        type: 'TRIEDWORDS',
        triedWordState
    }
}

export const wordgridAction = (wordgridState) => {
    return {
        type: 'WORDGRID',
        wordgridState
    }
}

export const shobdleAction = (shobdleState) => {
    return {
        type: 'SHOBDLE',
        shobdleState
    }
}

export const positiongridAction = (positiongridState) => {
    return {
        type: 'POSITIONGRID',
        positiongridState
    }
}

export const shobdleCheckerAction = (shobdleCheckerState) => {
    return {
        type: 'SHOBDLECHECKER',
        shobdleCheckerState
    }
}


export const uploadShobdleAction = (uploadShobdleState) => {
    return {
        type: 'UPLOADSHOBDLE',
        uploadShobdleState
    }
}
