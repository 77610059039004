import React from "react";
import { IoIosCheckmarkCircle, IoIosCloseCircle } from "react-icons/io";
import "./statusIcon.css";

const StatusIcon = ({
  status
}) => {

  return (
    <div className="status-icon">
      <div className="status-icon-svg">
        {status === "win" ? <IoIosCheckmarkCircle size={20} style={{color: 'green'}}/> 
        : <IoIosCloseCircle size={20} style={{color: 'red'}}/>}
      </div>
    </div>
  );
};

export default StatusIcon;
