import React, { useState } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import {signInAction} from '../../actions';
import { useNavigate } from 'react-router-dom';
import ReactDOM  from 'react-dom';
import './signIn.css';
import {signAnonimously, uploadShobdle } from '../../helpers/firebase';

function SignIn() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const shobdle = useSelector(state => state.isUploadShobdle);
    const [userName, setUserName] = useState('');
    const [nameError, setNameError] = useState('');

    // Sign in with Google when clicked
   const handleClick = event => {
    // Validate user's name
    if (userName.trim() === '') {
      setNameError('তোমার নাম দিতে হবে তো!');
      return;
    }

    signAnonimously({displayName: userName})
      .then((user) => {
        // Do something with the signed-in user
         // Upload the shobdle to the database
        if (shobdle !== null) {
          uploadShobdle(user.uid, shobdle)
          .then((success) => {
              if (success) {
              // Upload completed successfully
              navigate(`/profile/${user.uid}`);
              } else {
              // Upload failed
              console.log("Upload failed.");
              }
          })
          .catch((error) => {
              // Handle any errors during the upload
              console.error("Error during upload:", error);
          });
        } else {
          navigate(`/profile/${user.uid}`);
        }
      })
      .catch((error) => {
        // Handle errors
        console.error('Error signing in with Facebook:', error);
      });
      dispatch(signInAction(false));
   };

   // Handle input change
  const handleNameChange = (event) => {
    setUserName(event.target.value);
    // Reset name error when the user starts typing
    setNameError('');
  };

    return ReactDOM.createPortal(
        <div className='signin_bg'>
            <div className="signin">
              <div className= "signin-close-icon">
                  <AiOutlineCloseCircle size={22} onClick={() => dispatch(signInAction(false))}/>
              </div>
              <div className="signin_body">
                  <p className='shobdle_logo'>শব্দল</p>
                  <p className='signin-title'>আমার অ্যাকাউন্ট</p>
                  <div className='signin-input'>
                    <input
                      type="text"
                      placeholder="তোমার নাম"
                      value={userName}
                      onChange={handleNameChange}
                      className="signin-input-field"
                    />
                    {nameError && <p className="error-message">{nameError}</p>}
                  </div>
                  <button className="anonymous-login-button" onClick={handleClick}>
                  Continue
                  </button>
              </div>
          </div>
        </div>,
        document.getElementById('portal')
      );
}

export default SignIn;