import React from "react";
import { useTimer } from "react-timer-hook";
import "./timer.css";

function Timer({ expiryTimestamp }) {
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

  return (
    <div className="timer">
        <div style={{ textAlign: "center" }}>
            <div>
                <span className="timer-box">{hours}</span>:
                <span className="timer-box">{minutes}</span>:
                <span className="timer-box">{seconds}</span>
            </div>
        </div>
    </div>
  );
}

export default Timer;
