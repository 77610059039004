import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { helpAction } from "../../actions";
import ReactDOM from "react-dom";
import contrastHelpImage from "../../assets/colorblind-shobdle.png";
import normalHelpImage from "../../assets/normal-shobdle.png";
import { AiOutlineCloseCircle } from "react-icons/ai";
import "./help.css";


function Help() {
  const dispatch = useDispatch();
  const historyObject = useSelector((state) => state.isHistoryData);

  const modalRef = useRef(null);
  const imageRef = useRef(null);

  function handleClick(event) {
    if (event.target !== imageRef.current) {
        dispatch(helpAction(false));
    }
  }

  return ReactDOM.createPortal(
    <div ref={modalRef} id="help-container" onClick={handleClick} data-theme={historyObject.theme}>
      <div className="help-body">
        <div className= "help-close-icon">
            <AiOutlineCloseCircle size={27} onClick={() => dispatch(helpAction(false))}/>
        </div>
        <img ref={imageRef} src={historyObject.contrast ? contrastHelpImage : normalHelpImage} alt="shobdle help" id="image" ></img>
      </div>
    </div>,
    document.getElementById("portal")
  );
}

export default Help;
