import React, {Suspense} from 'react';
import {RouterConfig} from './routes/Routes';
import './App.css';

function App () {

  return (
  <div className="App">
    <Suspense fallback={<div>Loading...</div>}>
      <RouterConfig/>
    </Suspense>
  </div>
  );
}

export default App;
