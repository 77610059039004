const settingsReducer = (state = false, action) => {
    switch (action.type) {

        case "SETTINGS":
            return action.settingsState
            ;

        default: return state;
    }
};


export default settingsReducer;