import keyStateReducer from "./isKeyStateUpdated";
import gameoverReducer from "./gameover";
import historyReducer from "./showHistory";
import historyDataReducer from "./historyData";
import settingsReducer from "./showSettings";
import helpReducer from "./showHelp";
import switchReducer from "./switch";
import shiftReducer from "./shiftClicked";
import triedWordsReducer from "./triedWords";
import wordgridReducer from "./wordgrid";
import shobdleReducer from "./isShobdle";
import { combineReducers } from "redux";
import positiongridReducer from "./positionGrid";
import signInReducer from "./showSignIn";
import shobdleChekerReducer from "./shobdleChecker";
import uploadShobdleReducer from "./uploadShobdle";

const allReducers = combineReducers(
    {
        isSignIn: signInReducer,
        isKeyStateUpdated: keyStateReducer,
        isGameover: gameoverReducer,
        isHistory: historyReducer,
        isHistoryData: historyDataReducer, 
        isSettings: settingsReducer,
        isHelp: helpReducer,
        isSwitch: switchReducer, 
        isShiftClicked: shiftReducer,
        isTriedWords: triedWordsReducer,
        isWordGrid: wordgridReducer,
        isShobdle: shobdleReducer,
        isPositiongrid: positiongridReducer,
        isShobdleChecker: shobdleChekerReducer,
        isUploadShobdle: uploadShobdleReducer
    }
);

export default allReducers;