const uploadShobdleReducer = (state = null, action) => {
    switch (action.type) {

        case "UPLOADSHOBDLE":
            return {
                ...state,
                ...action.uploadShobdleState
            };

        default: return state;
    }
};


export default uploadShobdleReducer;
