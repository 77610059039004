const historyDataReducer = (state = {}, action) => {
    switch (action.type) {

        case "HISTORYDATA":
            return {
                ...state,
                ...action.historyDataState

            };

        default: return state;
    }
};


export default historyDataReducer;