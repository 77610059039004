import  {wordBank}  from '../shobdleBank.js';
export const wordgridDefault = [
    ["", "", "", ""],
    ["", "", "", ""],
    ["", "", "", ""],
    ["", "", "", ""],
    ["", "", "", ""],
    ["", "", "", ""],
    ["", "", "", ""],
  ];
  export const positionGridDefault = [
    ["", "", "", ""],
    ["", "", "", ""],
    ["", "", "", ""],
    ["", "", "", ""],
    ["", "", "", ""],
    ["", "", "", ""],
    ["", "", "", ""],
  ];

  //Firebase Configuration
  export const FBAPIKEY = "AIzaSyAansMpPQUZb5N0_Z4VDt6AppqlpE5vS28";
  export const FBAUTHDOMAIN = "shobdle-36062.firebaseapp.com";
  export const PROJECTID = "shobdle-36062";
  export const STORAGEBUCKET = "shobdle-36062.appspot.com";
  export const MESSAGESENDERID = "971638607113";
  export const APPID = "1:971638607113:web:a477b21425bf26879317d8";
  export const MEASUREMENTID = "G-L57MQHZB62";

  export const alphabets = ["অ", "ই", "উ", "ক", "চ", "ট", "ত", "প", "ৎ","আ", "ঈ", "ঊ", "খ", "ছ", "ঠ", "থ", "ফ", "ঃ","এ", "ও", "ঋ", "গ", "জ", "ড", "দ", "ব", "ড়","ঐ", "ঔ", "ল", "ঘ", "ঝ", "ঢ", "ধ", "ভ", "ঢ়", "ঙ", "ন", "ম", "স", "য", "র","ঞ", "ণ", "শ", "ষ", "হ", "য়"];
  export const diarects = ["া", "ি", "ী", "ু", "ূ", "ৃ", "ৄ", "ে", "ৈ", "ো", "ৌ", "ঁ", "্য"];
  export const hosonto = ["্"];
  
  export const generateWordSet = () => {
    let wordSet;
   // let todaysWord;
    wordSet = new Set(wordBank);

    return{wordSet};
  };
  
