const helpReducer = (state = false, action) => {
    switch (action.type) {

        case "HELP":
            return action.helpState
            ;

        default: return state;
    }
};


export default helpReducer;