import React from 'react';
import { Alphabet } from '../../components';
import './wordgrid.css';

function Wordgrid () {
    
    return(
        <div className="wordgrid">
            {" "}

            <div className="row">
                <Alphabet alphabetPos={0} attemptVal={0} gridPos={0}/>
                <Alphabet alphabetPos={1} attemptVal={0} gridPos={1}/>
                <Alphabet alphabetPos={2} attemptVal={0} gridPos={2}/>
                <Alphabet alphabetPos={3} attemptVal={0} gridPos={3}/>
            </div>
            <div className="row">
                <Alphabet alphabetPos={0} attemptVal={1} gridPos={4}/>
                <Alphabet alphabetPos={1} attemptVal={1} gridPos={5}/>
                <Alphabet alphabetPos={2} attemptVal={1} gridPos={6}/>
                <Alphabet alphabetPos={3} attemptVal={1} gridPos={7}/>
            </div>
            <div className="row">
                <Alphabet alphabetPos={0} attemptVal={2} gridPos={8}/>
                <Alphabet alphabetPos={1} attemptVal={2} gridPos={9}/>
                <Alphabet alphabetPos={2} attemptVal={2} gridPos={10}/>
                <Alphabet alphabetPos={3} attemptVal={2} gridPos={11}/>
            </div>
            <div className="row">
                <Alphabet alphabetPos={0} attemptVal={3} gridPos={12}/>
                <Alphabet alphabetPos={1} attemptVal={3} gridPos={13}/>
                <Alphabet alphabetPos={2} attemptVal={3} gridPos={14}/>
                <Alphabet alphabetPos={3} attemptVal={3} gridPos={15}/>
            </div>
            <div className="row">
                <Alphabet alphabetPos={0} attemptVal={4} gridPos={16}/>
                <Alphabet alphabetPos={1} attemptVal={4} gridPos={17}/>
                <Alphabet alphabetPos={2} attemptVal={4} gridPos={18}/>
                <Alphabet alphabetPos={3} attemptVal={4} gridPos={19}/>
            </div>
            <div className="row">
                <Alphabet alphabetPos={0} attemptVal={5} gridPos={20}/>
                <Alphabet alphabetPos={1} attemptVal={5} gridPos={21}/>
                <Alphabet alphabetPos={2} attemptVal={5} gridPos={22}/>
                <Alphabet alphabetPos={3} attemptVal={5} gridPos={23}/>
            </div>
            <div className="row">
                <Alphabet alphabetPos={0} attemptVal={6} gridPos={24}/>
                <Alphabet alphabetPos={1} attemptVal={6} gridPos={25}/>
                <Alphabet alphabetPos={2} attemptVal={6} gridPos={26}/>
                <Alphabet alphabetPos={3} attemptVal={6} gridPos={27}/>
            </div>
            
        </div>
    );
}

export default Wordgrid;